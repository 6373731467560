import api from '@/plugins/http'

export default {
  namespaced: true,
  state: {
    store: {},
    view_store: {},
    tags: [],
    cities: [],
    stores: [],
    categories: [],
    products: [],
    product: {},
    service_types: [],
    search_pickup_times: [],
    transaction_session: null,
    payment_response: false,
    company: {}
  },
  mutations: {
    UPDATE_STORE (state, data) {
      state.store = data
    },
    UPDATE_COMPANY (state, data) {
      state.company = data
    },
    UPDATE_VIEW_STORE (state, data) {
      state.view_store = data
    },
    UPDATE_TAGS (state, data) {
      state.tags = data
    },
    UPDATE_CITIES (state, data) {
      state.cities = data
    },
    UPDATE_STORES (state, data) {
      state.stores = data
    },
    UPDATE_CATEGORIES (state, data) {
      state.categories = data
    },
    UPDATE_PRODUCTS (state, data) {
      state.products = data
    },
    UPDATE_PRODUCT (state, data) {
      state.product = data
    },
    UPDATE_SERVICE_TYPES (state, data) {
      state.service_types = data
    },
    UPDATE_SEARCH_PICKUP_TIMES (state, data) {
      state.search_pickup_times = data
    },
    UPDATE_TRANSACTION_SESSION (state, data) {
      state.transaction_session = data
    },
    UPDATE_PAYMENT_RESPONSE (state, data) {
      state.payment_response = data
    },
    UPDATE_STORE_DELIVERY_PICKUP_TIME (state, data) {
      state.store.delivery_pickup_times = data
    },
  },
  actions: {
    async getTags ({ commit }) {
      try {
        const response = await api.get(`/tag`)
        commit('UPDATE_TAGS', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getCities ({ commit }) {
      try {
        const response = await api.get(`/city`)
        commit('UPDATE_CITIES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getSearch ({ commit }, {searchTag, serviceType, searchAddress, distance, pickup_time_day, pickup_time_time}) {
      try {
        const response = await api.get(`/store?searchTag=${searchTag ? searchTag : ''}&serviceType=${serviceType ? serviceType : ''}&searchAddress=${searchAddress ? searchAddress : ''}&distance=${distance}&pickup_time_day=${pickup_time_day ? pickup_time_day : ''}&pickup_time_time=${pickup_time_time ? pickup_time_time : ''}`)
        commit('UPDATE_STORES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getStores ({ commit }, {latitude, longitude, distance}) {
      try {
        const response = await api.get(`/store?latitude=${latitude}&longitude=${longitude}&distance=${distance}`)
        commit('UPDATE_STORES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getTagStores ({ commit }, {latitude, longitude, searchTag, distance}) {
      try {
        const response = await api.get(`/store?latitude=${latitude}&longitude=${longitude}&searchTag=${searchTag}&distance=${distance}`)
        commit('UPDATE_STORES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getCategories ({ commit }, id) {
      try {
        const response = await api.get(`/store/${id}/category`)
        commit('UPDATE_CATEGORIES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getProducts ({ commit }, id) {
      try {
        const response = await api.get(`/store/${id}/product`)
        commit('UPDATE_PRODUCTS', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getCategoryProducts ({ commit }, id) {
      try {
        const response = await api.get(`/category/${id}/product`)
        commit('UPDATE_PRODUCTS', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getProduct ({ commit }, id) {
      try {
        const response = await api.get(`/product/${id}`)
        commit('UPDATE_PRODUCT', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getStore ({ commit }, {id, latitude, longitude}) {
      try {
        const response = await api.get(`/store/${id}?latitude=${latitude}&longitude=${longitude}`)
        commit('UPDATE_STORE', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getChain ({ commit }, id) {
      try {
        const response = await api.get(`/company/${id}`)
        commit('UPDATE_COMPANY', response.data())
        const response1 = await api.get(`/chain/${id}`)
        commit('UPDATE_STORES', response1.data())
        return response1.successful()
      } catch (e) {
        return false
      }
    },
    async updateViewStore ({ commit }, id) {
      try {
        const response = await api.get(`/store/${id}`)
        commit('UPDATE_VIEW_STORE', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getServiceTypes ({ commit }) {
      try {
        const response = await api.get(`/get_service_types`)
        commit('UPDATE_SERVICE_TYPES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getSearchPickupTimes ({ commit }) {
      try {
        const response = await api.get(`/get_search_pickup_times`)
        commit('UPDATE_SEARCH_PICKUP_TIMES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getApplePay ({ commit }, { validationURL, orderID }) {
      try {
        const response = await api.post(`/apple_pay/apple/pay/merchant`, { validationURL: validationURL, orderID: orderID})
        commit('UPDATE_TRANSACTION_SESSION', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getApplePayment ({ commit }, { paymentToken, orderID }) {
      try {
        const response = await api.post(`/apple_pay/apple/pay/payment`, { paymentToken: paymentToken, orderID: orderID})
        commit('UPDATE_PAYMENT_RESPONSE', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getDeliveryPickupTime ({ commit }, {id, address, phone}) {
      try {
        const response = await api.post(`/store/get_delivery_pickup_time/${id}`, {address: address,phone: phone})
        commit('UPDATE_STORE_DELIVERY_PICKUP_TIME', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
  }
}
